<template>
  <section class="main-login">
    <div class="main-box flexed">
      <div class="login-logo-box">
        <img src="../../assets/images/logo_1080.png" alt="抖工科技">
      </div>
      <Card class="login-card" icon="log-in" :bordered="false">
        <span slot="title" v-if="$route.params.type == 2">
            商家登录
        </span>
        <span slot="title" v-else>
            企业登录
        </span>
        <div class="form-con">
          <Form ref="loginForm" autocomplete="off" :model="loginForm" :rules="loginRules">
            <FormItem prop="phone">
              <Input size="large" autofocus class="login_input" v-model="loginForm.phone" placeholder="请输入手机号">
              </Input>
            </FormItem>
            <FormItem prop="password">
              <Input size="large" class="login_input" type="password" v-model="loginForm.password" placeholder="请输入登录密码">
              </Input>
            </FormItem>
            <FormItem>
              <Button
                size="large"
                class="button-login"
                @click="loginSubmit('loginForm')"
                type="primary"
                long>
                登录
              </Button>
            </FormItem>
          </Form>
          <div class="bottom-btn-list flexed">
            <span class="login-span" @click="forgetPassword">忘记密码</span>
            <span class="login-span" @click="register">注册企业</span>
          </div>
        </div>
      </Card>

    </div>
  </section>
</template>

<script>
import md5 from "js-md5"
  export default {
    data() {
      return {
        // 表单对象
        loginForm:{},
        // 表单验证
        loginRules:{
          phone:[
            {type: 'number',required: true,message: '请填写正确的手机号',transform(value){
                var msg = /^[1][3,4,5,6,7,8,9][0-9]{9}$/;
                if(!msg.test(value)) {
                  return false;
                }
                else {
                  return Number(value);
                }
              }}
            ],
          password:[
            {required:true,message:'密码不能为空'},
            {type:"string",min:8,message:"密码不少于8位"},
            {type:"string",max:20,message:"密码不大于20位"},
            ]
        }
      }
    },
    created() {
      const userInfo = this.common.getStorage(this.CONSTANTS.USER_INFO)
      if (userInfo) {
        // 登陆时需要去掉原来的缓存
        this.storage.remove(this.CONSTANTS.USER_INFO)
        this.storage.remove(this.CONSTANTS.EOVA_AUTH)
      }
    },
    methods: { 
      // 登录跳转
      loginSubmit(loginForm) {
        let obj = {...this.loginForm};
        this.$refs[loginForm].validate(valid => {
          if(valid) {
            let time = new Date().getTime()
            let sign = obj.phone.substring(5) + obj.phone + md5(obj.password) + time + obj.phone.substring(0,5)
            let param = {phoneNumber:obj.phone, password:md5(obj.password),timestamp:time,sign:md5(sign)}
            this.$axios.post("https://api.dougongapp.com/dgtech/api/mgmt/oauth/password/login", param).then(async res => {
            // this.$axios.post("http://api.dougongapp.com/dgtech/api/mgmt/oauth/password/login", param).then(async res => {
            // this.$axios.post("/dgtech/api/mgmt/oauth/password/login", param).then(async res => {

            // let param = {login_id:obj.phone, login_pwd:obj.password}
            // this.$axios.post("/rlapi/common/login", param).then(async res => {
              // console.log(res);
              if (res.data.resultStatus) {
                // 登陆成功时同时获取userInfo, 并设置相关缓存
                this.common.setAuth(res.data.resultData);
                await this.common.getUserInfoSync(res)
                if (this.$route.params.type == 2) {
                  if(this.common.getStorage(this.CONSTANTS.USER_INFO).maxRoleType=='ADMINISTRATOR'){
                      this.$router.push({ name: 'myConsole'});
                  }else{
                      this.$router.push({ name: 'upload'});
                  }
                } else if(this.common.getStorage(this.CONSTANTS.USER_INFO).dg_key == 9 || this.common.getStorage(this.CONSTANTS.USER_INFO).dg_key == 10){
                    if(this.common.getStorage(this.CONSTANTS.USER_INFO).maxRoleType=='ADMINISTRATOR'){
                      this.$router.push({ name: 'myConsole'});
                  }else{
                      this.$router.push({ name: 'myProject'});
                  }

                  // this.$router.push({ name: 'myProject'});
                } else {
                  if(this.common.getStorage(this.CONSTANTS.USER_INFO).maxRoleType=='ADMINISTRATOR'){
                      this.$router.push({ name: 'myConsole'});
                  }else{
                      this.$router.push({ name: 'myProject'});
                  }
                  // this.$router.push({ name: 'myProject'});
                }
              } else {
                this.$Message.error(res.data.errorMessage);
              }
            })
            // .catch(err => {
            //   console.log(err);
            //   this.$Message.error(err.message);
            // })
            // this.$axios.post("/rlapi/common/login", {
            //   data: param,
            //   method: 'post',
            // }).then(async res => {
            //   if (res) {
            //     // 登陆成功时同时获取userInfo, 并设置相关缓存
            //     this.common.setAuth(res.data);
            //     await this.common.getUserInfoSync()
            //     if (this.$route.params.type == 2) {
            //       this.$router.push({ name: 'upload'});
            //     } else if(this.common.getStorage(this.CONSTANTS.USER_INFO).dg_key == 9 || this.common.getStorage(this.CONSTANTS.USER_INFO).dg_key == 10){
            //       this.$router.push({ name: 'newConsole'});
            //     } else {
            //       this.$router.push({ name: 'myProject'});
            //     }
            //   } 
            // })
          }
        })
      },
      // 注册跳转
      register() {
        this.$router.push({ name: 'registerEnterprise' })
      },
      // 忘记密码跳转
      forgetPassword(){
        this.$router.push({ name: 'forgetPassword' })
      },
      returnHome() {
        this.$router.push({name:"home"})
      },
      toProtocolPage (type) {
        this.common.toProtocolPage(type);
      },
      jumpToMobile() {
        let flag = this.common.isMobile();
        if (flag) {
          window.location.href = this.CONSTANTS.MOBILE_LOGIN_URL;
        }
      }
    },
    mounted() {
      this.jumpToMobile();
    }
  }
</script>

<style lang="stylus">
  @import '../../assets/styles/login'
</style>
